// These are helper classes based on the `tailwind.css` library
// TODO: Not sure if we should follow this pattern...

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-stretch {
  align-items: stretch;
}

.flex-1 {
  flex: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.mobile\:hidden {
  @include mobile {
    display: none;
  }
}

.rounded-sm {
  border-radius: 4px;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}
.space-y-4 > * + * {
  margin-top: 1rem;
}

.focus\:vy-outline:focus {
  box-shadow: 0 0 0 2px $link;
}

.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-normal {
  white-space: normal;
}

@each $name, $space in $spacing-values {
  .-my-#{$name} {
    margin-top: -$space;
    margin-bottom: -$space;
  }
  // unused:
  // @each $dir in ('top', 'right', 'bottom', 'left') {
  //   .-m#{str-slice($dir, 0, 1)}-#{$name} {
  //     margin-#{$dir}: -$space;
  //   }
  // }
}
