// Forked from: '~react-day-picker/dist/style.css';

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #{darken($primary, 20%)};
  --rdp-background-color: #{$primary-lightest};
  --rdp-accent-color-dark: #{$primary};
  --rdp-background-color-dark: #a70d55;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-light: #eee;

  margin: 0.5rem;
}

$small-b-radius: 4px;

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;
  border: 0;
}

.rdp-button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

// .rdp-button {
//   border: 2px solid transparent;
// }

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

// .rdp-button:focus-visible:not([disabled]) {
//   color: inherit;
//   background-color: var(--rdp-background-color);
//   border: var(--rdp-outline);
// }
// custom:
.rdp-nav_button:not([disabled]):focus-visible {
  box-shadow: 0 0 0 2px var(--rdp-accent-color);
  background-color: var(--rdp-background-color);
}
.rdp-nav_button:not([disabled]):hover {
  background-color: var(--rdp-background-color);
}

// .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
//   background-color: var(--rdp-background-color);
// }

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  margin: 0;
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
}

.rdp-multiple_months .rdp-caption {
  position: relative;
  display: block;
  text-align: center;
}

.rdp-caption_dropdowns {
  position: relative;
  display: inline-flex;
}

.rdp-caption_label {
  position: relative;
  z-index: 0; // was 1
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0.25em;
  border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row,
.rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center !important;
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  color: $grey;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: 0.5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}

.rdp-weeknumber {
  font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  // overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 0;
  // border: 2px solid transparent;
  // border-radius: 100%;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

// .rdp-day_selected,
// .rdp-day_selected:focus-visible,
// .rdp-day_selected:hover {
//   color: white;
//   opacity: 1;
// }

// .rdp-day_selected:focus-visible {
//   /* Since the background is the same use again the outline */
//   outline: var(--rdp-outline);
//   outline-offset: 2px;
//   z-index: 1;
// }

// .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
// }

// .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
// }

// .rdp-day_range_end.rdp-day_range_start {
//   border-radius: 100%;
// }

// .rdp-day_range_middle {
//   border-radius: 0;
// }

// CUSTOM STYLES:

@mixin round_inner($bg-color: false, $s: 1px) {
  position: relative;
  z-index: 0;
  &::before {
    position: absolute;
    top: $s;
    left: $s;
    right: $s;
    bottom: $s;
    content: '';
    z-index: -1;
    border-radius: 50%;
    @if $bg-color {
      background-color: $bg-color;
    }
  }
}

.rdp-row + .rdp-row .rdp-day {
  margin-top: 2px;
}

.rdp-day_range_start,
.rdp-day_range_end,
.rdp-day_range_middle {
  background-color: var(--rdp-selected-light);
  color: black;
}
.rdp-day_range_start,
.rdp-day_range_end {
  @include round_inner($primary);
  color: white;
}

.rdp-day_range_start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.rdp-day_range_end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.rdp-cell:first-child,
.rdp-cell:empty + .rdp-cell:not(:empty) {
  .rdp-day_range_end:not(.rdp-day_range_start),
  .rdp-day_range_middle {
    border-top-left-radius: $small-b-radius;
    border-bottom-left-radius: $small-b-radius;
  }
}
.rdp-cell:last-child {
  .rdp-day_range_start:not(.rdp-day_range_end),
  .rdp-day_range_middle {
    border-top-right-radius: $small-b-radius;
    border-bottom-right-radius: $small-b-radius;
  }
}

@mixin wider_border($color, $s: 1px) {
  @include round_inner();
  &::before {
    outline: 1.5px solid $color;
    outline-offset: 1px;
  }
}

.rdp-day:not([disabled]):focus-visible {
  @include wider_border(var(--rdp-accent-color));
}
.rdp-day:not([disabled]):not(.rdp-day_range_middle):hover {
  @include wider_border(var(--rdp-accent-color));
}
.rdp-day_range_middle:not([disabled]):hover {
  @include round_inner(#f7f7f7);
  &::before {
    box-shadow: 0 0 0 1px #ddd;
  }
  // same as: @include wider_border(#ddd, 1px);
}

@mixin fade_out_cell($dir) {
  position: absolute;
  z-index: -2;
  content: '';
  #{$dir}: -48px;
  top: 0;
  width: 54px;
  height: 100%;
  background-image: linear-gradient(to #{$dir}, var(--rdp-selected-light) 0%, transparent 100%);
}
.rdp-cell:not(:first-child) .rdp-day_first_of_month {
  &.rdp-day_range_middle,
  &.rdp-day_range_end {
    &::after {
      @include fade_out_cell(left);
    }
  }
}
.rdp-cell:not(:last-child) .rdp-day_last_of_month {
  &.rdp-day_range_middle,
  &.rdp-day_range_start {
    &::after {
      @include fade_out_cell(right);
    }
  }
}
