@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';

@import '~bulma/sass/elements/box.sass';
@import '~bulma/sass/elements/button.sass';
@import '~bulma/sass/elements/container.sass';
@import '~bulma/sass/elements/content.sass';
@import '~bulma/sass/elements/icon.sass';
@import '~bulma/sass/elements/image.sass';
@import '~bulma/sass/elements/notification.sass';
@import '~bulma/sass/elements/progress.sass';
@import '~bulma/sass/elements/table.sass';
@import '~bulma/sass/elements/tag.sass';
@import '~bulma/sass/elements/title.sass';
@import '~bulma/sass/elements/other.sass';

@import '~bulma/sass/form/shared.sass';
@import '~bulma/sass/form/input-textarea.sass';
@import '~bulma/sass/form/checkbox-radio.sass';
@import '~bulma/sass/form/select.sass';
@import '~bulma/sass/form/file.sass';
@import '~bulma/sass/form/tools.sass';

// @import '~bulma/sass/components/breadcrumb.sass';
@import '~bulma/sass/components/card.sass';
@import '~bulma/sass/components/dropdown.sass';
@import '~bulma/sass/components/level.sass';
@import '~bulma/sass/components/media.sass';
@import '~bulma/sass/components/menu.sass';
@import '~bulma/sass/components/message.sass';
@import '~bulma/sass/components/modal.sass';
@import '~bulma/sass/components/navbar.sass';
// @import '~bulma/sass/components/pagination.sass';
@import '~bulma/sass/components/panel.sass';
@import '~bulma/sass/components/tabs.sass';

@import '~bulma/sass/grid/columns.sass';
// @import '~bulma/sass/grid/tiles.sass';

@import '~bulma/sass/helpers/_all.sass';

@import '~bulma/sass/layout/hero.sass';
@import '~bulma/sass/layout/footer.sass';
@import '~bulma/sass/layout/section.sass';

// bulma extensions:
// @import '~bulma-checkradio/src/sass/index.sass';
@import './checkradio';
@import '~bulma-switch/src/sass/index.sass';
@import '~bulma-divider/src/sass/index.sass';
@import '~bulma-timeline/src/sass/index.sass';
// @import '~bulma-slider/src/sass/index.sass'; // doesn't render right
@import '~bulma-steps/src/sass/index.sass';
@import '~bulma-tooltip/src/sass/index.sass';

.content p {
  font-family: $family-secondary;
  color: $grey;
  line-height: 180%;
}
.content h2 {
  font-weight: 600;
  &:not(:first-child) {
    margin-top: 2em;
  }
}

.button {
  transition: color 0.2s ease, background-color 0.2s ease;
}

.progress::-webkit-progress-value {
  transition: width 0.5s ease;
}

.navbar-item {
  font-size: 14px;
  font-weight: 600;

  .button {
    font-size: inherit;
    transition: background-color 0.1s ease;

    &:not(.is-link):hover {
      background-color: $grey-lightest;
    }
  }
}
// a.navbar-item:hover {
//   border-bottom-color: $grey-lighter;
// }
.navbar-dropdown .navbar-item {
  font-weight: 400;
}

a.navbar-item {
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s ease;

  &.is-active {
    border-bottom-color: $link;
  }

  &:hover {
    border-bottom-color: lighten($link, 30%);
  }
}

// .navbar-dropdown button.navbar-item {
//   padding-right: 3rem;
//   color: $link;
//   &:hover {
//     background: whitesmoke;
//   }
// }

.navbar.is-transparent {
  background-color: transparent;
  background-image: none;
  .navbar-item:hover {
    text-decoration: underline;
  }
}

.panel-block {
  border-left: 2px solid transparent;
  // already the default
  // &.is-active {
  //   border-left-color: $link;
  // }
}
