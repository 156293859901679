@mixin button-reset {
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
  text-align: inherit;
}

@mixin landing_show_alt_bg {
  @media screen and (max-width: $tablet), screen and (max-height: 830px) {
    @content;
  }
}

@mixin sr_only {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  left: auto !important;
  top: auto !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  margin: 0;
}

@mixin active_filter_dot {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 99px;
  background: var(--dot-fill, $primary);
  margin-right: 6px;
}

@mixin map_control_button {
  border-radius: 99px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
