.booking-cover-container {
  margin-top: 20px;
  width: 100% !important;
  height: 150px;
  overflow: hidden;

  img {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
}

.listing-link-button {
  width: 100%;
  height: 80px;
  background: hsl(0, 0%, 96%);
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 1px 0px #ccc;
}

.listing-link-photo {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
  }
}

.booking-status,
.booking-status__tag {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem !important;
  cursor: help;
  align-content: center;

  &::before {
    font-size: 0.5rem;
    font-weight: normal;
    text-transform: none;
  }
}

.booking-status-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;

  &__help {
    margin-left: 10px;
    font-size: 0.6rem !important;
    border: 1px solid gray;
    border-radius: 50%;
    padding: 1px 6px 2px;
  }
}

.image-thumb {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 6px;
  img {
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 6px;
    // height: 100%;
  }
}

.image-thumb.small {
  height: 80px;
  width: 80px;
}

.date {
  display: flex;
  .date-info {
    margin-left: 10px;
  }
}

.date-box {
  width: 50px;
  height: 50px;
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 29%);
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
  padding: 6px;
  line-break: strict;
  border-radius: 6px;
  text-transform: uppercase;
  align-items: center;
  word-spacing: 50px;

  .month {
    font-size: 0.85rem;
    display: block;
  }

  .day {
    font-size: 1rem;
    display: block;
  }
}
