.react-calendar {
  @apply tw-font-sans-serif tw-text-base;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  /* background-color: #e6e6e6; */
  @apply tw-bg-off-white tw-text-ebony;
}

.react-calendar__month-view__weekdays {
  @apply tw-text-gray tw-text-left;
}

abbr:where([title]) {
  text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  box-shadow: inset 0 0 0 0.5px #bfbda3;
  @apply tw-font-bold tw-text-xl tw-font-secondary tw-text-ebony tw-p-4 tw-flex tw-flex-col tw-items-start tw-gap-8;
}

.react-calendar__tile:disabled {
  @apply tw-bg-[#EBD8C0] tw-text-gray;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #e6e6e6; */
  @apply tw-bg-off-white;
}

.react-calendar__tile--now {
  /* background: #ffff76; */
  @apply tw-border tw-rounded-md tw-border-ebony;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background: #ffffa9; */
}

.react-calendar__tile--hasActive {
  /* background: #76baff; */
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  /* background: #a9d4ff; */
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active.past-date,
.react-calendar__tile--active.past-date:enabled:hover,
.react-calendar__tile--active.past-date:enabled:focus,
.react-calendar__tile--active.not-available,
.react-calendar__tile--active.not-available:enabled:hover,
.react-calendar__tile--active.not-available:enabled:focus {
  @apply tw-bg-ebony tw-text-off-white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* background: #1087ff; */
}

.react-calendar--selectRange .react-calendar__tile--hover {
  /* background-color: #e6e6e6; */
}

.not-available {
  background-image: repeating-linear-gradient(
    45deg,
    rgba(235, 235, 235, 0.5),
    rgba(235, 235, 235, 0.5) 10px,
    rgba(220, 220, 220, 0.5) 10px,
    rgba(220, 220, 220, 0.5) 20px
  );
}

.synced-calendar-not-available {
  background-image: repeating-linear-gradient(
    45deg,
    rgba(254, 252, 232, 0.6),
    rgba(254, 252, 232, 0.6) 10px,
    rgba(254, 249, 195, 0.6) 10px,
    rgba(254, 249, 195, 0.6) 20px
  );
}
